import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'


import './style.css';

import { connect } from 'react-redux';
import { getTranslations, addNewTranslate } from './../../../redux/actions/translations/action';
import * as action_types from './../../../redux/actions/translations/action_types';
import api from './../../../lib/api'
import Loader from './../../loader/loader'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
            width: '100%'
    }

})
const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
    typography: { useNextVariants: true },
});
class addNewTranslation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching:false
        }
    }

    componentDidMount() {
        const { getTranslations } = this.props;
        getTranslations('en');
    }
    handleSubmit=(event) =>{
        event.preventDefault();
         const formData = new FormData(event.target)
         const user = {};
         event.preventDefault()

         for (let entry of formData.entries()) {
             if(entry[0]==='CountryCode'){
                user[entry[0]] = entry[1].toLowerCase()
             }else{
                user[entry[0]] = entry[1]
             }
         }
             
        if(user.CountryCode != '' && user.CountryCode.length == 2) {
            this.setState({isFetching:true})
            api.updateSiteContent({
                data: user
             }).then(res=>{
                this.setState({isFetching:false})
                alert('Added Sucessfully.');
                document.getElementById("add-new-form").reset();
            });
        }else if(user.CountryCode == '') {
            alert('country code can not be null');
        }else if(user.CountryCode.length > 2 || user.CountryCode.length < 2) {
            alert('country code must be two digit');
        }
       
      }

    render() {
        const { classes, translationsReducer } = this.props;
        let translations = translationsReducer.translations;
        let ArrayData = [];
        if(translations.success) {
            ArrayData = translations.data;
        }
        return (
            <div>
                {this.state.isFetching ? <Loader/> :null}
                <div className="new-lanuage-translation">
                    <h3>Add new language translation</h3>
                </div>
                {/* <MuiThemeProvider theme={theme}> */}
                <form onSubmit={this.handleSubmit} id="add-new-form">
                     <TextField
                        className={classes.textField}
                        label="Enter Language Code"
                        id="mui-theme-provider-standard-input"
                        name="CountryCode"
                     />

                    {
                         ArrayData.map(form => {   
                            return (
                                <TextField
                                className={classes.textField}
                                label={form.EnglishText}
                                id="multiline-static"
                                name={`${form.Id}`}
                                multiline={true}
                                rowsMax={10}
                                InputLabelProps={{
                                    shrink: true
                                  }}
                            />
                            )
                         })
                    }
                   
                    <div className="button-section">
                        <Button type="submit" variant="contained" color="primary" className={classes.button}>
                            Save
                        </Button>
                        <Link to="/">
                            <Button variant="contained" color="secondary" className={classes.button}>
                                Go back
                            </Button>
                         </Link>   
                        
                    </div>
                    </form>
                {/* </MuiThemeProvider> */}
            </div >
        )
    }
}

addNewTranslation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    translationsReducer: state.translationsReducer
});

const mapDispatchToProps = (dispatch) => ({
    getTranslations: (countrycode) => dispatch(getTranslations(countrycode)),
    addNewTranslate: (data) => dispatch(addNewTranslate(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(addNewTranslation))


import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


import './style.css';

import { connect } from 'react-redux';
import { getTranslations, addNewTranslate, getCountryList } from '../../../redux/actions/translations/action';
import * as action_types from '../../../redux/actions/translations/action_types';
import api from '../../../lib/api'
import Loader from '../../loader/loader'

const styles = theme => ({
    textField: {
        margin: theme.spacing.unit,
        width: '100%'
    }

})
const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
    typography: { useNextVariants: true },
});
class addNewTranslation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching: false,
            countrycode: 'en',
            selectedKey: '',
        }
    }

    componentDidMount() {
        this.props.getCountryList()
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)
        const user = {};
        for (let entry of formData.entries()) {
            user[entry[0]] = entry[1]
        }
        if(user.TextKey.match(/\ /)) {
            alert('Key should not include space.')
            return;
        }
           
        this.setState({ isFetching: true })
        api.updateFooterContent({
            data: user
        }).then(res => {
            this.setState({ isFetching: false,selectedKey:'' })
            alert('Added Sucessfully.');
            document.getElementById("add-new-form").reset();
        });
       

    }
    selectCountryCode = (e) => {
        console.log(e.target.value)
        this.setState({
            countrycode: e.target.value
        })
    }
    handleKeySelect = (e) => {
        console.log(e.target.value)
        this.setState({
            selectedKey: e.target.value
        })
    }
    render() {
        const { classes, translationsReducer } = this.props;
        let { countryCodeList } = translationsReducer;
        const { countrycode ,selectedKey} = this.state;

        return (
            <div>
                {this.state.isFetching ? <Loader /> : null}
                <div className="new-lanuage-translation">
                    <h3>Add new footer link</h3>
                </div>
                {/* <MuiThemeProvider theme={theme}> */}
                <form onSubmit={this.handleSubmit} id="add-new-form">
                    <div className="countrycode-dropdown">
                        <p style={{ color: 'black' }}>Select Country Code</p>
                        <Select
                            value={countrycode}
                            onChange={this.selectCountryCode}
                            name="CountryCode"

                        >
                            {countryCodeList ? countryCodeList.data.map((item, index) => {
                                return (<MenuItem key={index} value={item.CountryCode}>{item.CountryCode}</MenuItem>)

                            }) : null}
                        </Select>
                    </div>
                    <FormControl className={classes.formControl} style={{width:'100%'}}>
                        <InputLabel id="demo-simple-select-label">Select Key</InputLabel>
                        <Select
                            value={selectedKey}
                            onChange={this.handleKeySelect}
                            name="TextKey"
                            placeholder="Enter Language Country Code"

                        >
                                {/* <MenuItem key="" value="">None</MenuItem> */}
                                <MenuItem key="unsubscribe" value="unsubscribe">unsubscribe</MenuItem>
                                <MenuItem key="tearms_and_condition" value="tearms_and_condition">tearms_and_condition</MenuItem>
                                <MenuItem key="privacy_policy" value="privacy_policy">privacy_policy</MenuItem>
                                <MenuItem key="OtherTextKey" value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        selectedKey==="Other" ? 
                        <TextField
                        className={classes.textField}
                        label="Enter other key"
                        id="mui-theme-provider-standard-input"
                        name="TextKey"
                        required
                     />:null
                    }
                  

                    {/* <TextField
                        className={classes.textField}
                        label={`TextKey`}
                        id="mui-theme-provider-standard-input"
                        name={`TextKey`}
                        required
                    /> */}

                    <TextField
                        className={classes.textField}
                        label={`Link`}
                        id="mui-theme-provider-standard-input"
                        name={`Link`}
                        required
                    />

                    <div className="button-section">
                        <Button type="submit" variant="contained" color="primary" className={classes.button}>
                            Save
                        </Button>
                        <Link to="/footerAdmin">
                            <Button variant="contained" color="secondary" className={classes.button}>
                                Go back
                            </Button>
                        </Link>

                    </div>
                </form>
                {/* </MuiThemeProvider> */}
            </div >
        )
    }
}

addNewTranslation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    translationsReducer: state.translationsReducer
});

const mapDispatchToProps = (dispatch) => ({
    addNewTranslate: (data) => dispatch(addNewTranslate(data)),
    getCountryList: () => dispatch(getCountryList()),

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(addNewTranslation))


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';




import { connect } from 'react-redux';
import { getFooterLinks, updateFooterLinkById ,getCountryList} from '../../../redux/actions/translations/action';
import api from '../../../lib/api'
import Loader from '../../loader/loader'

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    textField: {
        width: '100%',
        height: '50px !important'
    }
});

let id = 0;
function createData(name, calories, fat, carbs, protein) {
    id += 1;
    return { id, name, calories, fat, carbs, protein };
}

class footerAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedRow: null,
            isEdit: false,
            inputValue: '',
            countrycode: 'en',
            isFetching:false
        }
    }
    componentWillMount() {
        const { getFooterLinks,getCountryList } = this.props;
        console.log(getFooterLinks, 'getFooterLinks')
        getFooterLinks('en');
        getCountryList()
    }
    handleEdit = (data) => {
        this.setState({
            selectedRow: data.Id,
            isEdit: true,
            inputValue: data.Link
        })
    }
    handleAdd = ({ Id }) => {
        const { getFooterLinks } = this.props;
        this.setState({isFetching:true})

        let data = { "Id": this.state.selectedRow, "Link": this.state.inputValue }

        api.updateFooterLinkById(data).then(res => {
            getFooterLinks(this.state.countrycode)
            this.setState({
                isEdit: false,
                selectedRow: null,
                isFetching:false
            })
        })

    }
    handleDelete = ( Id ) => {
        if(window.confirm(`Are you sure you want to delete ${Id} !!`)){
            const { getFooterLinks } = this.props;
            let data = { "Id": Id }
            this.setState({isFetching:true})
            api.deleteFooterContentById(data).then(res => {
                getFooterLinks(this.state.countrycode)
                this.setState({
                    isEdit: false,
                    selectedRow: null,
                    isFetching:false
                })
            })
        }
       

    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            inputValue: e.target.value
        })
    }

    handleCheck = (e) => {
        const { getFooterLinks } = this.props;
        this.setState({isFetching:true})

        if(window.confirm(`Are you sure you want to update !!`)){
            
            let data = { "Id": this.state.selectedRow, "IsEnable": this.state.inputValue }

            api.updateFooterLinkById(data).then(res => {
                getFooterLinks(this.state.countrycode)
                this.setState({
                    isEdit: false,
                    selectedRow: null,
                    isFetching:false
                })
            })
        }
    }

    selectCountryCode = (e) => {
        console.log(e.target.value)
        const { getFooterLinks } = this.props;
        this.setState({
            countrycode: e.target.value
        })
        getFooterLinks(e.target.value)
    }
    render() {
        const { classes, translationsReducer } = this.props;
        const { selectedRow, isEdit, countrycode ,isFetching} = this.state;
        let {footerLists,countryCodeList} = translationsReducer;
        console.log("translationsReducer", translationsReducer)

        return (
            <div>
                {isFetching ? <Loader/> :null}
                <div className="countrycode-dropdown">
                <p style={{ color: 'black' }}>Select Country Code</p>
                <Select
                    value={countrycode}
                    onChange={this.selectCountryCode}
                >
                {countryCodeList ? countryCodeList.data.map((item,index)=>{
                   return( <MenuItem key={index} value={item.CountryCode}>{item.CountryCode}</MenuItem>)

                }):null}
                </Select>
                </div>
                <Paper className={classes.root}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>ID</CustomTableCell>
                                <CustomTableCell align="right">Text</CustomTableCell>
                                <CustomTableCell align="right">Link</CustomTableCell>
                                <CustomTableCell align="right">Contry Code</CustomTableCell>
                                <CustomTableCell align="right">Action(Enable/Disable)</CustomTableCell>
                                <CustomTableCell align="right">Action</CustomTableCell>
                                <CustomTableCell align="right">Action</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        {footerLists ?
                        <TableBody>
                            {footerLists.success ? footerLists.data.map(row => (
                                <TableRow className={classes.row} key={row.Id}>
                                    <CustomTableCell component="th" scope="row">{row.Id}</CustomTableCell>
                                    <CustomTableCell align="right">{row.TextKey}</CustomTableCell>
                                    {selectedRow === row.Id ?
                                        <TextField
                                            id="standard-name"
                                            className={'textField'}
                                            value={this.state.inputValue}
                                            onChange={(e) => this.handleChange(e)}
                                            margin="normal"
                                        /> : <CustomTableCell align="right">{row.Link}</CustomTableCell>}
                                    <CustomTableCell align="right">{row.CountryCode}</CustomTableCell>
                                    <CustomTableCell align="right">
                                        <Checkbox 
                                            id="standard-checkbox"
                                            onChange={(e) => this.handleCheck(e)}
                                            value={row.IsEnable}
                                        >
                                        </Checkbox>
                                    </CustomTableCell>
                                    
                                    {isEdit && selectedRow === row.Id ? <CustomTableCell align="right" style={{cursor:'pointer',color:'blue'}} onClick={() => this.handleAdd(row)}>{"Add"}</CustomTableCell> :
                                        <>
                                        <CustomTableCell align="right"  onClick={() => this.handleEdit(row)}><div style={{cursor:'pointer',color:'blue'}}>Edit</div></CustomTableCell>
                                        <CustomTableCell align="right" style={{cursor:'pointer',color:'blue'}} onClick={() => this.handleDelete(row.Id)}>{"Delete"}</CustomTableCell>
                                        </>
                                        }
                                </TableRow>
                            )) : null}
                        </TableBody>
                        : null }
                    </Table>
                    <Link to="/addNewFooterLink">
                        <Fab color="primary" aria-label="Add" className="add-button">
                            <AddIcon />
                        </Fab></Link>
                </Paper>
            </div>
        );
    }

}

footerAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
};



const mapStateToProps = (state) => ({
    translationsReducer: state.translationsReducer
});

const mapDispatchToProps = (dispatch) => ({
    getFooterLinks: (countrycode) => dispatch(getFooterLinks(countrycode)),
    updateFooterLinkById: (data) => dispatch(updateFooterLinkById(data)),
    getCountryList: () => dispatch(getCountryList()),

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(footerAdmin))
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

import './style.css';

import { connect } from 'react-redux';
import { getTranslations, addNewSiteField } from './../../../redux/actions/translations/action';
import * as action_types from './../../../redux/actions/translations/action_types';
import api from './../../../lib/api'
import Loader from './../../loader/loader'


const styles = theme => ({
    textField: {
        margin: theme.spacing.unit,
        width: '100%'
    }

})
const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
    typography: { useNextVariants: true },
});
class AddNeFieldsTranslation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching:false
        }
    }

    componentDidMount() {
        const { getTranslations } = this.props;
        getTranslations('en');
    }
    handleSubmit=(event) =>{
        event.preventDefault();
         const formData = new FormData(event.target)
         const user = {};
         event.preventDefault()
        this.setState({isFetching:true})
        for (let entry of formData.entries()) {
            user[entry[0]] = entry[1]
        }
             
        api.addNewSiteField({
            data: user
            }).then(res=>{
            console.log(res);
            document.getElementById("add-new-form").reset();
            this.setState({isFetching:false},()=>{
                alert('Added Sucessfully.');
            })
        });
        
      }

    render() {
        const { classes, translationsReducer } = this.props;
        let translations = translationsReducer.translations;
        let ArrayData = [];
        if(translations.success) {
            ArrayData = translations.data;
        }
        return (
            <div>
                {this.state.isFetching ? <Loader/> :null}
                <div className="new-lanuage-translation">
                    <h3>Add New Field</h3>
                </div>
                {/* <MuiThemeProvider theme={theme}> */}
                <form onSubmit={this.handleSubmit} id="add-new-form">
                     <TextField
                        className={classes.textField}
                        label="Enter Language Country Code"
                        id="mui-theme-provider-standard-input"
                        name="CountryCode"
                        value="en"
                        disabled={true}
                     />

                    <TextField
                        className={classes.textField}
                        label="Add New Field Key"
                        id="mui-theme-provider-standard-input"
                        name="newKey"
                        required
                    />

                    <TextField
                        className={classes.textField}
                        label="Add New Field to English Lang"
                        id="mui-theme-provider-standard-input"
                        name="newField"
                        required
                    />
                    
                    <div className="button-section">
                        <Button type="submit" variant="contained" color="primary" className={classes.button}>
                            Save
                        </Button>
                            <Link to="/">
                            <Button variant="contained" color="secondary" className={classes.button}>
                                Go back
                            </Button>
                            </Link>
                        
                    </div>
                    </form>
                {/* </MuiThemeProvider> */}
            </div >
        )
    }
}

AddNeFieldsTranslation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    translationsReducer: state.translationsReducer
});

const mapDispatchToProps = (dispatch) => ({
    getTranslations: (countrycode) => dispatch(getTranslations(countrycode)),
    addNewSiteField: (data) => dispatch(addNewSiteField(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNeFieldsTranslation))


import * as action_type from './../actions/translations/action_types';
import { connect } from 'react-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from './../../lib/api';


function* getTranslations(action) {
    try {
        const data = yield api.getTranslations(action.countrycode);
        console.log("data",data)
        yield put({ type: action_type.API_TRANSLATION_SUCCESS, data })
    }
    catch (e) {
        yield put({ type: action_type.API_TRANSLATION_FAIL, data:{success:false} });
    }
}

function* getFooterLinks(action) {
    try {
        const data = yield api.getFooterLinks(action.countrycode);
        console.log("data",data)
        yield put({ type: action_type.API_FOOTERLINKS_SUCCESS, data })
    }
    catch (e) {
        yield put({ type: action_type.API_FOOTERLINKS_FAIL, data:{success:false} });
    }
}

function* updateTranslateById(action) {
    try {
        const data = yield api.updateTranslateById(action.data);
        yield put({ type: action_type.API_UPDATE_TRANSLATION_SUCCESS, data })
    }
    catch (e) {
        yield put({ type: action_type.API_UPDATE_TRANSLATION_FAIL, data:{success:false} });
    }
}

function* updateFooterLinkById(action) {
    try {
        const data = yield api.updateFooterLinkById(action.data);
        yield put({ type: action_type.API_UPDATE_FOOTERLINKS_SUCCESS, data })
    }
    catch (e) {
        yield put({ type: action_type.API_UPDATE_FOOTERLINKS_FAIL, data:{success:false} });
    }
}

function* getCountryList() {
    try {
        const data = yield api.getCountryList();
        yield put({ type: action_type.API_GET_COUNTRY_CODE_SUCCESS, data })
    }
    catch (e) {
        yield put({ type: action_type.API_GET_COUNTRY_CODE_FAIL, data:{success:false} });
    }
}

export const TranslationsSaga = [
    takeEvery(action_type.API_TRANSLATION_FETCH, getTranslations),
    takeEvery(action_type.API_UPDATE_TRANSLATION_FETCH, updateTranslateById),
    takeEvery(action_type.API_GET_COUNTRY_CODE_FETCH, getCountryList),
    takeEvery(action_type.API_FOOTERLINKS_FETCH, getFooterLinks),
    takeEvery(action_type.API_UPDATE_FOOTERLINKS_FETCH, updateFooterLinkById),

]
import * as action from './action_types';

export function getTranslations(countrycode) {
    return {
        type: action.API_TRANSLATION_FETCH,
        countrycode: countrycode
    }
};

export function getFooterLinks(countrycode) {
    return {
        type: action.API_FOOTERLINKS_FETCH,
        countrycode: countrycode
    }
};

export function updateTranslateById (data) {
    return {
        type: action.API_UPDATE_TRANSLATION_FETCH,
        data: data
    }
};

export function updateFooterLinkById (data) {
    return {
        type: action.API_UPDATE_FOOTERLINKS_FETCH,
        data: data
    }
};

export function addNewTranslate (data) {
    return {
        type: action.API_ADD_TRANSLATION_SUCCESS,
        data: data
    }
};

export function addNewSiteField (data) {
    return {
        type: action.API_ADD_FIELD_TRANSLATION_SUCCESS,
        data: data
    }
};

export function getCountryList () {
    return {
        type: action.API_GET_COUNTRY_CODE_FETCH,
    }
};

export function deleteTranslateById (data) {
    return {
        type: action.API_DELETE_TRANSLATION_SUCCESS,
        data: data
    }
};
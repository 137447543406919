import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";

import './style.css';
import Header from './../../header/header';
import Translations from './translations'
import CountryConfig from './../countryConfig'
import footerAdmin from './footeradmin'
import AddNewTranslation from './../addNewTranslation/addNewTranslation'
import addNeFieldsTranslation from './../addNewTranslation/addNeFieldsTranslation'
import addNewFooterLink from '../addNewFooterLink/addNewFooterLink';


class Home extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="main-container">
                        <Route exact path="/" component={CountryConfig} />
                        <Route exact path="/translations" component={Translations} />
                        <Route path="/addNewTranslation" component={AddNewTranslation} />
                        <Route path="/addNeFieldsTranslation" component={addNeFieldsTranslation} />
                        <Route path="/footerAdmin" component={footerAdmin} />
                        <Route path="/addNewFooterLink" component={addNewFooterLink} />
                </div>
            </div>
        );
    }
}

export default Home;

export const API_TRANSLATION_FETCH = 'API_TRANSLATION_FETCH';
export const API_TRANSLATION_SUCCESS = 'API_TRANSLATION_SUCCESS';
export const API_TRANSLATION_FAIL = 'API_TRANSLATION_FAIL';

export const API_FOOTERLINKS_FETCH = 'API_FOOTERLINKS_FETCH';
export const API_FOOTERLINKS_SUCCESS = 'API_FOOTERLINKS_SUCCESS';
export const API_FOOTERLINKS_FAIL = 'API_FOOTERLINKS_FAIL';

export const API_UPDATE_TRANSLATION_FETCH = 'API_UPDATE_TRANSLATION_FETCH';
export const API_UPDATE_TRANSLATION_SUCCESS = 'API_UPDATE_TRANSLATION_SUCCESS';
export const API_UPDATE_TRANSLATION_FAIL = 'API_UPDATE_TRANSLATION_FAIL';

export const API_UPDATE_FOOTERLINKS_FETCH = 'API_UPDATE_FOOTERLINKS_FETCH';
export const API_UPDATE_FOOTERLINKS_SUCCESS = 'API_UPDATE_FOOTERLINKS_SUCCESS';
export const API_UPDATE_FOOTERLINKS_FAIL = 'API_UPDATE_FOOTERLINKS_FAIL';


export const API_ADD_TRANSLATION_FETCH = 'API_ADD_TRANSLATION_FETCH';
export const API_ADD_TRANSLATION_SUCCESS = 'API_ADD_TRANSLATION_SUCCESS';
export const API_ADD_TRANSLATION_FAIL = 'API_ADD_TRANSLATION_FAIL';

export const API_GET_COUNTRY_CODE_FETCH = 'API_GET_COUNTRY_CODE_FETCH';
export const API_GET_COUNTRY_CODE_SUCCESS = 'API_GET_COUNTRY_CODE_SUCCESS';
export const API_GET_COUNTRY_CODE_FAIL = 'API_GET_COUNTRY_CODE_FAIL';


export const API_ADD_FIELD_TRANSLATION_FETCH = 'API_ADD_FIELD_TRANSLATION_FETCH';
export const API_ADD_FIELD_TRANSLATION_SUCCESS = 'API_ADD_FIELD_TRANSLATION_SUCCESS';
export const API_ADD_FIELD_TRANSLATION_FAIL = 'API_ADD_FIELD_TRANSLATION_FAIL';

export const API_DELETE_TRANSLATION_FETCH = 'API_DELETE_TRANSLATION_FETCH';
export const API_DELETE_TRANSLATION_SUCCESS = 'API_DELETE_TRANSLATION_SUCCESS';
export const API_DELETE_TRANSLATION_FAIL = 'API_DELETE_TRANSLATION_FAIL';

import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
//---Components--//
import Home from './components/screens/home/home';
import Login from './components/screens/login/login';

// import auth from './utils/auth';
import Privateroute from './utils/privateroute';
// import './mobioastro/assets/css/style.css';
import history from './utils/history';
import './assets/css/style.css'


class App extends Component {



    render() {
        return (
            <div >
                <Router history={history}>
                    <Switch>
                        {/* <Route path="/login" component={Login} /> */}
                        <Route path="/" component={Home} />
                        {/* <Privateroute component={Home} /> */}
                    </Switch>
                </Router>
            </div>

        );


    }
}

export default App;

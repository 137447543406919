import * as action_type from './../actions/userAuthentication/action_types';
const initialState = {
    isLoggedIn: false,
    token:''



}
const UserAuthentication = (state = initialState, action) => {
    switch (action.type) {
        case action_type.API_USERAUTH_SUCCESS:
        console.log("myPlaintextPassword",action.data)
            return Object.assign({}, state, {
                token: action.data.token,
                isLoggedIn:true
            });
            case action_type.API_USERAUTH_FAIL:
            return Object.assign({}, state, {
                isLoggedIn:false
            });
        default:
            return state;
    }
}

export default UserAuthentication;
import { combineReducers, createStore, applyMiddleware } from 'redux';
import UserAuthentication from './reducers/userAuth';
import translationsReducer from './reducers/transalations';
import createSagaMiddleware from 'redux-saga'
import MainSaga from './redux-saga/mainSaga';


const sagaMiddleware = createSagaMiddleware()

const appReducer = combineReducers({
    UserAuthentication,
    translationsReducer
});

let store = createStore(appReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(MainSaga);

export default store;
import * as action_type from './../actions/translations/action_types';
const initialState = {
    translations:'',
    countryCodeList:'',
    footerLists:''

}
const translationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case action_type.API_TRANSLATION_SUCCESS:
            return Object.assign({}, state, {
                translations: action.data,
            });
        case action_type.API_FOOTERLINKS_SUCCESS:
                return Object.assign({}, state, {
                    footerLists: action.data,
            });
        case action_type.API_TRANSLATION_FAIL:
            return Object.assign({}, state, {
            });
        case action_type.API_FOOTERLINKS_FAIL:
                return Object.assign({}, state, {
            });
        case action_type.API_UPDATE_TRANSLATION_SUCCESS:
            return Object.assign({}, state, {
            });
        case action_type.API_ADD_TRANSLATION_SUCCESS:
            return Object.assign({}, state, {
            });  
        case action_type.API_GET_COUNTRY_CODE_SUCCESS:
            return Object.assign({}, state, {
                countryCodeList:action.data,
            });   
        default:
            return state;
    }
}

export default translationsReducer;
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import CloseOutlined from '@material-ui/icons/CancelOutlined';
import AddBox from '@material-ui/icons/AddBox';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCountry from './AddCountry'
import api from './../../../lib/api'
import './style.css';

const styles = theme => ({
    textField: {
        margin: theme.spacing.unit,
        width: '100%',
    },
    formControl: {
        margin: 30,
        minWidth: 200,
    },

})

class countryConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            allCountries: [],
            selectedCountry: { SubDomain: '' },
            mappedLang: [],
            langCodeLits: [],
            isVisibleLangList: false,
            defaultLang:null

        }
    }
    componentDidMount() {
        api.getCountryList().then(res => {
            this.setState({
                langCodeLits: res.data
            })
        }).catch(err => {
            console.log(err)
        })
        api.getAllCountry().then(res => {
            if (res.success) {
                this.setState({
                    allCountries: res.data,
                    selectedCountry: res.data[0]
                })
                api.getMappingList({
                    SubDomain: res.data[0].SubDomain
                }).then(res => {
                    this.findDefault(res.data)
                    this.setState({
                        mappedLang: res.data
                    })
                })
            }

        }).catch(err => {
            console.log(err)
        })
    }
    handleModal = () => {
        this.setState({
            open: !this.state.open
        })
    }
    handleDefaultSelect = (item) => {
        api.updateMapping({
            "LangCode": item.LangCode,
            "SubDomain": item.SubDomain,
            "IsDefaultLang": 1
        }
        ).then(res => {
            this.findDefault(res.data)

            this.setState({
                mappedLang: res.data
            })
            // alert("Removed!")
        }).catch(err => {
            console.log(err)
        })
    }
    handleDelete = (id, SubDomain) => {
        api.deleteNewMapping({
            Id: id,
            SubDomain: SubDomain
        }).then(res => {
            this.findDefault(res.data)
            this.setState({
                mappedLang: res.data
            })
        }).catch(err => {
            console.log(err)
        })
    }
    handleSelectCountry = (e) => {
        this.setState({
            selectedCountry: e.target.value
        }, () => {
            api.getMappingList({
                SubDomain: e.target.value.SubDomain
            }).then(res => {
                this.findDefault(res.data)
                this.setState({
                    mappedLang: res.data
                })
            })
        })
    }
    toggleLangList = () => {
        this.setState({
            isVisibleLangList: !this.state.isVisibleLangList
        })
    }
    findDefault=(data)=>{
        if(data.length==1){
            this.setState({
                defaultLang:data[0]
            })
        }else{
            let defaultLang=data.filter(item=>item.IsDefaultLang)
            if(defaultLang.length>0){
                this.setState({
                    defaultLang:defaultLang[0]
                })
            }else{
                this.setState({
                    defaultLang:null
                })
            }
        }
       
    }
    handleAddLang = (langCode) => {
        api.addNewMapping({
            "LangCode": langCode,
            "SubDomain": this.state.selectedCountry.SubDomain,
            "IsDefaultLang": 0
        }
        ).then(res => {
            this.findDefault(res.data)
            this.toggleLangList()
            this.setState({
                mappedLang: res.data
            })
            alert("New Language Added !")
        }).catch(err => {
            console.log(err)
        })
    }
    render() {
        const { props: { classes }, state: { open, allCountries, selectedCountry, mappedLang, langCodeLits, isVisibleLangList ,defaultLang} } = this;
        console.log("mappedLang", mappedLang)
        let newLangCodeLits=langCodeLits.filter(item=>{
            let isAvailable=true;
                mappedLang.forEach(lang=>{
                    if(item.CountryCode==lang.LangCode){
                        isAvailable=false;
                    }
                });
            return isAvailable;
        })
        console.log("newLangCodeLits",newLangCodeLits)
        return (
            <div >
                {/* <div>
                    <p className="title">Country Config</p>
                </div> */}
                <AddCountry handleModal={this.handleModal} open={open} />
                <div className="container">
                    <p>Country Details</p>
                    <div className="country-details-container section-wrapper">
                        <FormControl className={classes.formControl} >

                            <InputLabel id="demo-simple-select-outlined-label">Select Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedCountry}
                                onChange={this.handleSelectCountry}
                                label="Country"
                            >
                                {/* <MenuItem value="">
                                    <em>None</em>
                                </MenuItem> */}
                                {allCountries.map((item, index) => <MenuItem value={item}>{item.ContryName}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <TextField
                            className="textField"
                            label="Subdomain"
                            id="mui-theme-provider-standard-input"
                            name="TextKey"
                            value={selectedCountry.SubDomain}
                            disabled
                        />
                        <Button variant="contained" color="secondary" className="add-button" onClick={this.handleModal}>
                            Add New Country
                    </Button>
                    </div>
                </div>
                <div className="container">
                    <p>Languages Mapping</p>
                    <div className="lang-map-container section-wrapper">
                        <div className="direction-column">
                            <div className="mapped-lang">
                                {mappedLang.map((item, index) =>
                                    <div className={item.IsDefaultLang ? "lang-code default" : "lang-code" } >
                                        <CloseOutlined className="close-icon" onClick={() => this.handleDelete(item.Id, item.SubDomain)} />
                                        <span onClick={()=>this.handleDefaultSelect(item)} className="icon-text">{item.LangCode}</span>
                                    </div>

                                )}
                                <div className="add-lang-container">
                                    <AddBox className="add-icon" style={{ fontSize: '40px' }} onClick={this.toggleLangList} />
                                    {isVisibleLangList &&
                                        <div className="lang-list">
                                            {newLangCodeLits.map((item, index) => {
                                                return <span className="lang-text" onClick={() => this.handleAddLang(item.CountryCode)}>{item.CountryCode}</span>;
                                            })}
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div className="direction-column">
                            <spna className="main-lang-title">Main Language</spna>
                            <span className="main-lang-value">{defaultLang ? defaultLang.LangCode : 'Nothing is selected as main language'}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


countryConfig.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    translationsReducer: state.translationsReducer
});

const mapDispatchToProps = (dispatch) => ({
    // addNewTranslate: (data) => dispatch(addNewTranslate(data)),
    // getCountryList: () => dispatch(getCountryList()),

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(countryConfig))


import * as action_type from './../actions/userAuthentication/action_types';
import { put, takeEvery } from 'redux-saga/effects';
import api from './../../lib/api';


function* getUserStatus(action) {
    try {
        const data = yield api.getUSerStatus(action.data);
        console.log("data",data)
        yield put({ type: action_type.API_USERAUTH_SUCCESS, data })
    }
    catch (e) {
        yield put({ type: action_type.API_USERAUTH_FAIL, data:{success:false} });
    }
}

export const UserAuthSaga = [
    takeEvery(action_type.API_USERAUTH_FETCH, getUserStatus),

]
import React, { Component } from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { userAuth } from './../../../redux/actions/userAuthentication/action';
import * as action_types from './../../../redux/actions/userAuthentication/action_types';

import './style.css';
import history from './../../../utils/history';

class Login extends Component {
    constructor(props) {
        super(props);
        const countryCode = {
            'ee': 372,
            'es':34,
            'fr':33,
            'gr':30,
            'eg': 20
        }
        this.state = {
            msisdn: countryCode[0],
            isValid: false,
            errorMessage: '',
            domain: 'eg.mobioastro.com',
            username:'',
            password:''
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    isValid() {
        if (this.state.msisdn == '') {
            this.setState({
                isValid: false,
                errorMessage: "Phone number is required."
            });
            return false;
        }
        return true;
    }



    onSubmit(e) {
        e.preventDefault();
        const {username,password}=this.state;
        const creds = {
            username: username,
            password: password
          };
          this.props.getUserStatus(creds)
    //    axios.post('http://localhost:3000/api/signin',creds).then(res=>{
    //        console.log("res====>",res)
    //    })
    }   
    handleInput=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    componentDidUpdate(){
        console.log("userAccess===>",this.props.userAccess)
        if (this.props.userAccess.isLoggedIn){
            sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:this.props.userAccess.isLoggedIn}));
            history.push({
                pathname: '/'
            });
        }
    }

    render() {
        console.log("userAccess===>",this.props.userAccess)
        return (
            <div className="login animated fadeIn">
                <div className="justify-content-center">
                    <div>
                        <div className="login-page-header">
                            <div className="login-page-header-child">
                                <img className="login-page-logo" src={require('./../../../assets/images/Logo.png')} alt="MobioAstro" width="125px" />
                            </div>
                        </div>
                        <div className="login-page-content">
                            <div className="login-page-mobile_num">
                               Username :<input autoFocus type="text" name="username" value={this.state.username} onChange={this.handleInput}  className="LoginRoute-number" />
                               Password :<input autoFocus type="password" name="password" value={this.state.password} onChange={this.handleInput} className="LoginRoute-number" />
                                <button type="submit" onClick={this.onSubmit} className="LoginRoute-button">
                                    Login
                            <i className="icon-back"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    userAccess: state.UserAuthentication
});

const mapDispatchToProps = (dispatch) => ({
    getUserStatus: (token) => dispatch(userAuth(token)),

});



export default connect(mapStateToProps, mapDispatchToProps)(Login)
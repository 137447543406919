import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { userAuth } from './../redux/actions/userAuthentication/action';
import * as action_types from './../redux/actions/userAuthentication/action_types';
import App from './../App';


const mapStateToProps = (state) => ({
    status: state.UserAuthentication.status
});

const mapDispatchToProps = (dispatch) => ({
    getUserStatus: (token) => dispatch(userAuth(token)),

});

// const mapDispatchToProps = bindActionCreators(
//     userAuth
//     , dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App)
import React, { Component } from 'react';
import './style.css';

class Loader extends Component {
    render() {
        return (
            <div className="content-loader">
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" space="preserve"><g><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ff5f6c" fillOpacity="1" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ffecee" fillOpacity="0.12" transform="rotate(45 64 64)" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ffecee" fillOpacity="0.12" transform="rotate(90 64 64)" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ffecee" fillOpacity="0.12" transform="rotate(135 64 64)" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ffd6da" fillOpacity="0.25" transform="rotate(180 64 64)" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ffbec3" fillOpacity="0.41" transform="rotate(225 64 64)" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ffa4ab" fillOpacity="0.57" transform="rotate(270 64 64)" /><path d="M71 39.2V.4a63.6 63.6 0 0 1 33.96 14.57L77.68 42.24a25.53 25.53 0 0 0-6.7-3.03z" fill="#ff858f" fillOpacity="0.76" transform="rotate(315 64 64)" /><animateTransform attributeName="transform" type="rotate" values="0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64" calcMode="discrete" dur="720ms" repeatCount="indefinite"></animateTransform></g><g><circle fill="#ff5f6c" fillOpacity="1" cx="63.66" cy="63.16" r="12" /><animate attributeName="opacity" dur="720ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.5;1" values="1;0;1" /></g></svg>
            </div>

        )
    }
}
export default Loader;
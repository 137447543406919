import React, { Component } from 'react'
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import api from './../../../lib/api'
const styles = theme => ({


})

class AddCountry extends Component {

    handleSubmit = (event) => {
        event.preventDefault();
        const { handleModal } = this.props;
        const country = {};

        const formData = new FormData(event.target)
        for (let entry of formData.entries()) {
            country[entry[0]] = entry[1]

        }
        api.addNewCountry(country).then(res=>{
            if(res.success){
                alert('New Country Added!')
                handleModal();
            }

        }).catch(err=>{
            console.log("err",err.response)  
            alert(err.response.data.messsage)
        })
}
render() {
    const { classes, open, handleModal } = this.props;

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={`${classes.modal} modal-container`}
                open={open}
                onClose={handleModal}
                // BackdropComponent={Backdrop}
                disableBackdropClick
            >

                <div className={"modal-content"}>
                    {/* <Alert variant="filled" severity="success">
            This is a success alert — check it out!
          </Alert> */}
                    <p id="transition-modal-title">Add new country</p>
                    <form onSubmit={this.handleSubmit} id="add-new-form">

                        <div className="add-new-country-form">
                            <TextField
                                className={`${classes.textField}`}
                                label='Country Name'
                                name='ContryName'
                                id='country-Name'
                                required
                            />
                            <TextField
                                className={`${classes.textField} margin-top`}
                                label='Sub domain'
                                name='SubDomain'
                                id='sub-domain'
                                required
                            />
                            <div className="action-containar">
                                <Button type="submit" variant="contained" color="secondary" >
                                    Add
                                 </Button>
                                <Button variant="contained" color="primary" onClick={handleModal}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
}

AddCountry.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default (withStyles(styles)(AddCountry))


import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { UserAuthSaga } from './userAuthSaga';
import { TranslationsSaga } from './translationsSaga';

export default function* MainSaga() {
    try {
        yield all([
            ...UserAuthSaga,...TranslationsSaga])
    }
    catch (error) {
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';




import { connect } from 'react-redux';
import { getTranslations, updateTranslateById, getCountryList } from './../../../redux/actions/translations/action';
import api from './../../../lib/api'
import Loader from './../../loader/loader'

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    textField: {
        width: '100%',
        height: '50px !important'
    }
});

let id = 0;
function createData(name, calories, fat, carbs, protein) {
    id += 1;
    return { id, name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

class Translations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedRow: null,
            isEdit: false,
            inputValue: '',
            countrycode: 'en',
            isFetching: false
        }
    }
    componentWillMount() {
        const { getTranslations, getCountryList } = this.props;
        getTranslations('en');
        getCountryList()
    }
    handleEdit = (data) => {
        this.setState({
            selectedRow: data.Id,
            isEdit: true,
            inputValue: data.OtherLangText
        })
    }
    handleAdd = ({ Id }) => {
        const { getTranslations } = this.props;
        this.setState({ isFetching: true })

        let data = { "Id": this.state.selectedRow, "OtherLangText": this.state.inputValue }

        api.updateTranslateById(data).then(res => {
            getTranslations(this.state.countrycode)
            this.setState({
                isEdit: false,
                selectedRow: null,
                isFetching: false
            })
        })

    }
    handleDelete = (Id) => {
        if (window.confirm(`Are you sure you want to delete ${Id} !!`)) {
            const { getTranslations } = this.props;
            let data = { "Id": Id }
            this.setState({ isFetching: true })
            api.deleteTranslateById(data).then(res => {
                getTranslations(this.state.countrycode)
                this.setState({
                    isEdit: false,
                    selectedRow: null,
                    isFetching: false
                })
            })
        }


    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            inputValue: e.target.value
        })
    }
    selectCountryCode = (e) => {
        console.log(e.target.value)
        const { getTranslations } = this.props;
        this.setState({
            countrycode: e.target.value
        })
        getTranslations(e.target.value)
    }
    render() {
        const { classes, translationsReducer } = this.props;
        const { selectedRow, isEdit, countrycode, isFetching } = this.state;
        let { translations, countryCodeList } = translationsReducer;
        console.log("translationsReducer", translations)

        return (
            <div>
                {isFetching ? <Loader /> : null}
                <div className="countrycode-dropdown">
                    <div className="row">
                        <p style={{ color: 'black',marginRight:'20px' }}>Select Language Code</p>
                        <Select
                            value={countrycode}
                            onChange={this.selectCountryCode}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {countryCodeList ? countryCodeList.data.map((item, index) => {
                                return (<MenuItem style={{ textTransform: 'uppercase' }} value={item.CountryCode}>{item.CountryCode}</MenuItem>)

                            }) : null}
                        </Select>
                    </div>
                    <div className="row">
                        <div className="cube"/>
                        <span>Not Translated From English</span>
                    </div>
                </div>
                <Paper className={classes.root}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>ID</CustomTableCell>
                                <CustomTableCell align="left">Keys</CustomTableCell>
                                <CustomTableCell align="right">English Text</CustomTableCell>
                                <CustomTableCell align="right">Selected Lang Text</CustomTableCell>
                                {/* <CustomTableCell align="right">Contry Code</CustomTableCell> */}
                                <CustomTableCell align="right">Action</CustomTableCell>
                                <CustomTableCell align="right">Action</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {translations.success ? translations.data.map(row => (
                                <TableRow className={classes.row} key={row.Id}>
                                    <CustomTableCell component="th" scope="row">{row.Id}</CustomTableCell>
                                    <CustomTableCell component="th" scope="row">{row.TextKey}</CustomTableCell>
                                    <CustomTableCell align="right">{row.EnglishText}</CustomTableCell>
                                    {selectedRow === row.Id ?
                                        <TextField
                                            id="standard-name"
                                            label="textField"
                                            className={classes.textField}
                                            multiline
                                            rowsMax={12}
                                            value={this.state.inputValue}
                                            onChange={(e) => this.handleChange(e)}
                                        />
                                        : <CustomTableCell align="right" style={row.OtherLangText.localeCompare(row.EnglishText) === 0 ? { backgroundColor: '#f5a7a7' } : {}}>{row.OtherLangText}</CustomTableCell>}
                                    {/* <CustomTableCell align="right">{row.CountryCode}</CustomTableCell> */}
                                    {isEdit && selectedRow === row.Id ? <CustomTableCell align="right" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.handleAdd(row)}>{"Add"}</CustomTableCell> :
                                        <>
                                            <CustomTableCell align="right" onClick={() => this.handleEdit(row)}><div style={{ cursor: 'pointer', color: 'blue' }}>Edit</div></CustomTableCell>
                                            <CustomTableCell align="right" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.handleDelete(row.Id)}>{"Delete"}</CustomTableCell>
                                        </>
                                    }
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                    <Link to="/addNewTranslation">
                        <Fab color="primary" aria-label="Add" className="add-button">
                            <AddIcon />
                        </Fab></Link>
                </Paper>
            </div>
        );
    }

}

Translations.propTypes = {
    classes: PropTypes.object.isRequired,
};



const mapStateToProps = (state) => ({
    translationsReducer: state.translationsReducer
});

const mapDispatchToProps = (dispatch) => ({
    getTranslations: (countrycode) => dispatch(getTranslations(countrycode)),
    updateTranslateById: (data) => dispatch(updateTranslateById(data)),
    getCountryList: () => dispatch(getCountryList()),

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Translations))
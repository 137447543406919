import axios from 'axios';

const API_ENDPOINT="http://localhost:3006/api";
let api = {
    getUSerStatus: function (data) {

        return new Promise(function (resolve, reject) {

            axios.post(`${API_ENDPOINT}/signin`, data)
                .then(result => {
                    console.log(result.data.data)
                    resolve(result.data.data);
                }).catch(error => {
                    reject(error);
                    console.log("error", error)
                })

        })
    },

    getTranslations: function (countrycode) {
        console.log('fsrfsdf')
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/getSiteContent`, {
                "CountryCode": countrycode
            })
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    getFooterLinks: function (countrycode) {
        console.log('tetette')
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/getFooterContent`, {
                "CountryCode": countrycode
            })
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    updateTranslateById: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/updateSiteContentById`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    updateFooterLinkById: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/updateFooterContentById`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    deleteTranslateById: function (data) {
        console.log(data)
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/deleteSiteContentById`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    deleteFooterContentById: function (data) {
        console.log(data)
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/deleteFooterContentById`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    updateSiteContent: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/updateSiteContent`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    updateFooterContent: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/updateFooterContent`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    getCountryList: function () {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/getCountryList`)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    addNewSiteField: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/addNewSiteField`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    addNewCountry: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/addNewCountry`, data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    getAllCountry: function (data) {
        return new Promise(function (resolve, reject) {
            axios.get(`${API_ENDPOINT}/getAllCountry`)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    getMappingList: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/getMappingList`,data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    addNewMapping: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/addNewMapping`,data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    updateMapping: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/updateMapping`,data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },
    deleteNewMapping: function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(`${API_ENDPOINT}/deleteNewMapping`,data)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

}
export default api;
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Assessment} from '@material-ui/icons';

import {Link} from 'react-router-dom';

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },
};
const sideList = (
    <div>
      <List>
      <Link to="/">
          <ListItem button key={'country_config'}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Country Config" />
          </ListItem>
          </Link>
          <Link to="/translations">
          <ListItem button key={'translations'}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Translations" />
          </ListItem>
          </Link>
          <Link to="/addNewTranslation">
          <ListItem button key={'translations'}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Add New Language" />
          </ListItem>
          </Link>
          <Link to="/addNeFieldsTranslation">
          <ListItem button key={'translations'}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Add New Field" />
          </ListItem>
          </Link>
          <Link to="/footerAdmin">
          <ListItem button key={'footer'}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Footer" />
          </ListItem>
          </Link>
          <Link to="/addNewFooterLink">
          <ListItem button key={'footer'}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Add New Footer Link" />
          </ListItem>
          </Link>
          

      </List>
    </div>
  );
class Header extends React.Component{
    constructor(props){
        super(props)
        this.state={
            left: false
        }
    }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
render(){
  const { classes } = this.props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton className={classes.menuButton} onClick={this.toggleDrawer('left', true)} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Mobioastro Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </Drawer>
    </div>
  );
    }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);